* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  display: flex;
  flex-direction: column;
  align-items: center; }

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0px 20px; }
  .container-footer {
    display: flex;
    flex-direction: row; }

section {
  width: 100%;
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.col-1-of-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 16.6666%;
  margin-right: 16px; }

.col-6-of-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  width: 50%; }

@media (max-width: 767px) {
  .container-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .col-1-of-12 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0px;
    gap: 16px; }
  .col-6-of-12 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0px;
    text-align: center; } }

@media (max-width: 479px) {
  .col-1-of-12 {
    flex-wrap: wrap; } }

h1 {
  margin-top: 144px;
  font-family: "Segoe UI", sans-serif;
  font-size: 76px;
  line-height: 76px;
  font-weight: 700;
  color: #1c4261;
  margin-bottom: 16px; }

h2 {
  font-family: "Segoe UI", sans-serif;
  font-size: 57px;
  margin-bottom: 64px;
  font-weight: 700;
  text-align: center;
  color: #1c4261; }

h5, .icon {
  font-family: "Segoe UI", sans-serif;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
  text-align: left;
  color: #ffffff; }

.icon {
  color: #394f61; }

p {
  font-family: "Segoe UI", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  max-width: 515px;
  color: #000; }

@media (max-width: 479px) {
  h1 {
    font-size: 57px;
    line-height: 57px;
    margin-bottom: 8px; }
  h2 {
    font-size: 43px;
    line-height: 43px; } }

nav {
  padding: 0px 20px;
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px; }

.logo {
  font-family: "Segoe UI", sans-serif;
  color: #4299e1;
  font-weight: 700;
  text-decoration: none;
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase; }

.nav_links_wrapper {
  width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.nav_link {
  font-family: "Segoe UI", sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; }
  .nav_link:hover {
    color: rgba(255, 255, 255, 0.5); }

.hamburger {
  display: none;
  cursor: pointer; }

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background-color: #fff; }

@media (max-width: 991px) {
  .hamburger {
    display: block; }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0; }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg); }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg); }
  .nav_links_wrapper {
    position: absolute;
    top: -100%;
    flex-direction: column;
    background-color: #1c4261;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease-in; }
  .nav_link {
    margin: 16px 0px; }
  .nav_links_wrapper.active {
    left: 0px;
    top: 0; } }

@media (max-width: 767px) {
  .logo {
    font-size: 24px;
    line-height: 24px; } }

.hero {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(85.53deg, rgba(255, 255, 255, 0.8) 34.37%, rgba(255, 255, 255, 0) 61.74%), url("/Assets/hero_img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom; }

@media (max-width: 991px) {
  .hero {
    background: linear-gradient(85.53deg, rgba(255, 255, 255, 0.8) 49.37%, rgba(255, 255, 255, 0) 76.74%), url("/Assets/hero_img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom; } }

@media (max-width: 767px) {
  .hero {
    height: 110vh;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.8) 60.37%, rgba(255, 255, 255, 0) 90.74%), url("/Assets/hero_img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; } }

@media (max-width: 479px) {
  .hero {
    height: auto;
    padding-bottom: 48px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.8) 60.37%, rgba(255, 255, 255, 0) 90.74%), url("/Assets/hero_img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; } }

.btn {
  display: inline-block;
  margin-top: 48px;
  margin-right: 24px;
  margin-bottom: 0px;
  padding: 16px 32px;
  background-color: #1c4261;
  font-family: "Segoe UI", sans-serif;
  line-height: 150%;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; }
  .btn:hover {
    background-color: #4299e1; }
  .btn-secondary {
    padding: 15px 31px;
    background-color: transparent;
    border: 1px solid #1c4261;
    color: #1c4261; }
    .btn-secondary:hover {
      background-color: rgba(66, 153, 225, 0.3);
      border: 1px solid rgba(66, 153, 225, 0.3); }

@media (max-width: 479px) {
  .btn {
    margin-top: 24px; } }

.cards-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap; }

.card {
  width: 100%;
  max-width: 405px;
  background-color: #394f61;
  border-radius: 24px;
  margin-right: 32px; }
  .card:nth-of-type(3) {
    margin-right: 0px; }

.card-icon {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 405px;
  border-radius: 24px;
  margin-right: 32px; }
  .card-icon:nth-of-type(3) {
    margin-right: 0px; }

.card-text-wrapper {
  padding: 32px; }
  .card-text-wrapper p {
    color: rgba(255, 255, 255, 0.6); }
  .card-text-wrapper-icon {
    padding: 0px;
    padding-left: 16px; }
    .card-text-wrapper-icon p {
      color: rgba(57, 79, 97, 0.8); }

.card-img {
  width: 100%;
  height: 232px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  object-fit: cover; }
  .card-img-icon {
    height: 48px;
    width: 48px;
    border-radius: 0px;
    display: inline; }

@media (max-width: 991px) {
  .cards-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; }
  .card {
    margin-right: 0px;
    margin-bottom: 32px; }
  .card-icon {
    margin-right: 0px;
    margin-bottom: 32px; } }

footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c4261;
  margin-top: 240px;
  padding: 48px 0px; }

.footer-link {
  font-family: "Segoe UI", sans-serif;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 24px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; }
  .footer-link:hover {
    color: #fff; }
  .footer-link-main {
    color: white;
    font-weight: 700; }

.footer-text {
  font-family: "Segoe UI", sans-serif;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  text-align: start;
  margin-bottom: 8px; }
  .footer-text:nth-of-type(2) {
    margin-top: 16px; }

@media (max-width: 767px) {
  .footer-text {
    text-align: center; } }
