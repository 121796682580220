* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
}

.container-footer {
  flex-direction: row;
  display: flex;
}

section {
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  display: flex;
}

.col-1-of-12 {
  width: 16.6666%;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 16px;
  display: flex;
}

.col-6-of-12 {
  text-align: left;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

@media (max-width: 767px) {
  .container-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col-1-of-12 {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-right: 0;
  }

  .col-6-of-12 {
    width: 100%;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
}

@media (max-width: 479px) {
  .col-1-of-12 {
    flex-wrap: wrap;
  }
}

h1 {
  color: #1c4261;
  margin-top: 144px;
  margin-bottom: 16px;
  font-family: Segoe UI, sans-serif;
  font-size: 76px;
  font-weight: 700;
  line-height: 76px;
}

h2 {
  text-align: center;
  color: #1c4261;
  margin-bottom: 64px;
  font-family: Segoe UI, sans-serif;
  font-size: 57px;
  font-weight: 700;
}

h5, .icon {
  text-align: left;
  color: #fff;
  margin-bottom: 8px;
  font-family: Segoe UI, sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.icon {
  color: #394f61;
}

p {
  width: 100%;
  max-width: 515px;
  color: #000;
  font-family: Segoe UI, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 479px) {
  h1 {
    margin-bottom: 8px;
    font-size: 57px;
    line-height: 57px;
  }

  h2 {
    font-size: 43px;
    line-height: 43px;
  }
}

nav {
  width: 100%;
  max-width: 1280px;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.logo {
  color: #4299e1;
  text-transform: uppercase;
  font-family: Segoe UI, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-decoration: none;
}

.nav_links_wrapper {
  width: 450px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav_link {
  color: #fff;
  font-family: Segoe UI, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s ease-in;
}

.nav_link:hover {
  color: #ffffff80;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px auto;
  transition: all .3s ease-in;
  display: block;
}

@media (max-width: 991px) {
  .hamburger {
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px)rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px)rotate(-45deg);
  }

  .nav_links_wrapper {
    width: 100%;
    text-align: center;
    background-color: #1c4261;
    flex-direction: column;
    transition: all .3s ease-in;
    position: absolute;
    top: -100%;
  }

  .nav_link {
    margin: 16px 0;
  }

  .nav_links_wrapper.active {
    top: 0;
    left: 0;
  }
}

@media (max-width: 767px) {
  .logo {
    font-size: 24px;
    line-height: 24px;
  }
}

.hero {
  width: 100%;
  height: 800px;
  background-color: #0000;
  background-image: linear-gradient(85.53deg, #fffc 34.37%, #fff0 61.74%), url("hero_img.f3b5d2a9.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 991px) {
  .hero {
    background-color: #0000;
    background-image: linear-gradient(85.53deg, #fffc 49.37%, #fff0 76.74%), url("hero_img.f3b5d2a9.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }
}

@media (max-width: 767px) {
  .hero {
    height: 110vh;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fffc 60.37%, #fff0 90.74%), url("hero_img.f3b5d2a9.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }
}

@media (max-width: 479px) {
  .hero {
    height: auto;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fffc 60.37%, #fff0 90.74%), url("hero_img.f3b5d2a9.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    padding-bottom: 48px;
  }
}

.btn {
  color: #fff;
  text-align: center;
  background-color: #1c4261;
  margin-top: 48px;
  margin-bottom: 0;
  margin-right: 24px;
  padding: 16px 32px;
  font-family: Segoe UI, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  transition: all .3s ease-in;
  display: inline-block;
}

.btn:hover {
  background-color: #4299e1;
}

.btn-secondary {
  color: #1c4261;
  background-color: #0000;
  border: 1px solid #1c4261;
  padding: 15px 31px;
}

.btn-secondary:hover {
  background-color: #4299e14d;
  border: 1px solid #4299e14d;
}

@media (max-width: 479px) {
  .btn {
    margin-top: 24px;
  }
}

.cards-wrapper {
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.card {
  width: 100%;
  max-width: 405px;
  background-color: #394f61;
  border-radius: 24px;
  margin-right: 32px;
}

.card:nth-of-type(3) {
  margin-right: 0;
}

.card-icon {
  width: 100%;
  max-width: 405px;
  border-radius: 24px;
  flex-direction: row;
  margin-right: 32px;
  display: flex;
}

.card-icon:nth-of-type(3) {
  margin-right: 0;
}

.card-text-wrapper {
  padding: 32px;
}

.card-text-wrapper p {
  color: #fff9;
}

.card-text-wrapper-icon {
  padding: 0 0 0 16px;
}

.card-text-wrapper-icon p {
  color: #394f61cc;
}

.card-img {
  width: 100%;
  height: 232px;
  object-fit: cover;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.card-img-icon {
  height: 48px;
  width: 48px;
  border-radius: 0;
  display: inline;
}

@media (max-width: 991px) {
  .cards-wrapper {
    width: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .card, .card-icon {
    margin-bottom: 32px;
    margin-right: 0;
  }
}

footer {
  width: 100%;
  background-color: #1c4261;
  justify-content: center;
  align-items: center;
  margin-top: 240px;
  padding: 48px 0;
  display: flex;
}

.footer-link {
  color: #fffc;
  margin-bottom: 24px;
  font-family: Segoe UI, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s ease-in;
}

.footer-link:hover {
  color: #fff;
}

.footer-link-main {
  color: #fff;
  font-weight: 700;
}

.footer-text {
  color: #fff;
  text-align: start;
  margin-bottom: 8px;
  font-family: Segoe UI, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.footer-text:nth-of-type(2) {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .footer-text {
    text-align: center;
  }
}

/*# sourceMappingURL=index.0cc4eaac.css.map */
